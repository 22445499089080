import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['icon']
  static values = {
    content: String,
  }

  contentValue!: string
  readonly iconTarget!: HTMLElement
  readonly hasIconTarget!: boolean

  async copy(): Promise<void> {
    await navigator.clipboard.writeText(this.contentValue)
    this.showSuccess()
  }

  private showSuccess(): void {
    const icon = this.iconTarget
    icon.innerHTML = this.checkMarkSvg
    icon.classList.add('text-green-600')

    setTimeout(() => {
      icon.innerHTML = this.clipboardSvg
      icon.classList.remove('text-green-600')
    }, 1000)
  }

  private get checkMarkSvg(): string {
    return `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
    </svg>`
  }

  private get clipboardSvg(): string {
    return `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
    </svg>`
  }
}
