import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  preview(event: Event): void {
    const input = event.target as HTMLInputElement

    if (input.files && input.files[0]) {
      const reader = new FileReader()
      const imagePreview = this.element.querySelector(
        '.image-preview-wrapper'
      ) as HTMLElement

      reader.onload = (e: ProgressEvent<FileReader>) => {
        const result = e.target?.result as string

        imagePreview.innerHTML = `
          <img src="${result}" class="inline-block size-10 rounded-full object-scale-down">
          <p class="text-sm text-gray-500">Uploading...</p>
        `

        // Automatically trigger the form submission
        setTimeout(() => {
          const form = input.closest('form')
          if (form) {
            form.requestSubmit()
          }
        }, 100)
      }

      reader.readAsDataURL(input.files[0])
    }
  }
}
