import { Controller } from '@hotwired/stimulus'

interface Group {
  id: string | number
  name: string
}

export default class extends Controller {
  static targets = ['input', 'options', 'parentId']

  readonly inputTarget!: HTMLInputElement
  readonly optionsTarget!: HTMLUListElement
  readonly parentIdTarget!: HTMLInputElement

  connect(): void {
    this.filterGroups = this.filterGroups.bind(this)
  }

  show(): void {
    this.optionsTarget.classList.remove('hidden')
  }

  hide(): void {
    setTimeout(() => {
      this.optionsTarget.classList.add('hidden')
      this.inputTarget.value =
        this.inputTarget.value || this.data.get('defaultName') || ''
    }, 200)
  }

  filterGroups(): void {
    const filter = this.inputTarget.value.toUpperCase()
    const items = this.optionsTarget.getElementsByTagName('li')

    for (const item of items) {
      const groupName = item.getElementsByTagName('span')[0]
      const txtValue = groupName.textContent || groupName.innerText
      item.style.display = txtValue.toUpperCase().includes(filter) ? '' : 'none'
    }
  }

  select(event: Event): void {
    const element = event.currentTarget as HTMLElement
    const group: Group | null = element.dataset.group
      ? JSON.parse(element.dataset.group)
      : null
    this.parentIdTarget.value = group ? String(group.id) : ''
    this.inputTarget.value = group ? group.name : 'None'
    this.optionsTarget.classList.add('hidden')
  }
}
