import { Controller } from '@hotwired/stimulus'

interface Workspace {
  id: string | number
  name: string
}

export default class WorkspaceSelectorController extends Controller<HTMLElement> {
  static targets = ['input', 'options', 'parentId']

  readonly inputTarget!: HTMLInputElement
  readonly optionsTarget!: HTMLElement
  readonly parentIdTarget!: HTMLInputElement

  connect(): void {
    this.filterWorkspaces = this.filterWorkspaces.bind(this)
    this.hideOptions = this.hideOptions.bind(this)
  }

  showOptions(): void {
    this.optionsTarget.classList.remove('hidden')
  }

  hideOptions(): void {
    setTimeout(() => {
      this.optionsTarget.classList.add('hidden')
      this.inputTarget.value =
        this.inputTarget.value || this.element.dataset.defaultName || ''
    }, 200)
  }

  filterWorkspaces(): void {
    const filter = this.inputTarget.value.toUpperCase()
    const items = this.optionsTarget.getElementsByTagName('li')

    for (const item of items) {
      const workspaceName = item.getElementsByTagName('span')[0]
      const txtValue = workspaceName.textContent || workspaceName.innerText
      item.style.display =
        txtValue.toUpperCase().indexOf(filter) > -1 ? '' : 'none'
    }
  }

  selectWorkspace(event: Event): void {
    const target = event.currentTarget as HTMLElement
    const workspace: Workspace = JSON.parse(target.dataset.workspace || '{}')
    this.parentIdTarget.value = String(workspace.id)
    this.inputTarget.value = workspace.name
    this.optionsTarget.classList.add('hidden')
  }
}
