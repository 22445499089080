import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  deleteFile(event: Event): void {
    event.preventDefault()
    const target = event.currentTarget as HTMLElement
    const fileId = target.dataset.fileId
    const fileItem = this.element.querySelector<HTMLElement>(
      `.file-item[data-file-id="${fileId}"]`
    )

    if (fileItem) {
      fileItem.remove()
    }
  }
}
