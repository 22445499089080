/* global require */
// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import '@hotwired/turbo-rails'
import '../channels'
import '../stylesheets/application.css'
import '../stylesheets/actiontext.css'

// jQuery
import $ from 'jquery'
import 'jquery/src/jquery'
window.$ = window.jQuery = $

// Charts for reports
import 'chartkick/chart.js'

// Stimulus
import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

const application = Application.start()
application.debug = false // Configure Stimulus development experience
const context = require.context('../controllers', true, /\.(js|ts)$/)
application.load(definitionsFromContext(context))
window.Stimulus = application

// Active Storage
import * as ActiveStorage from '@rails/activestorage'
ActiveStorage.start()

// Active Text
require('trix')
require('@rails/actiontext')
