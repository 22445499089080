import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tab', 'panel']

  readonly tabTargets!: HTMLAnchorElement[]
  readonly panelTargets!: HTMLElement[]

  initialize() {
    document.addEventListener('turbo:load', this.handleHashChange.bind(this))
    document.addEventListener('turbo:render', this.handleHashChange.bind(this))
    document.addEventListener(
      'turbo:frame-load',
      this.handleHashChange.bind(this)
    )
  }

  handleHashChange() {
    if (window.location.hash) {
      const currentTab = window.location.hash
      this.showTab(currentTab)
    }
  }

  connect(): void {
    const defaultTab =
      window.location.hash || this.tabTargets[0]?.getAttribute('href') || ''
    this.showTab(defaultTab)

    window.addEventListener('popstate', () => {
      const currentTab =
        window.location.hash || this.tabTargets[0]?.getAttribute('href') || ''
      this.showTab(currentTab)
    })
  }

  switch(event: Event): void {
    event.preventDefault()
    const target = event.currentTarget as HTMLAnchorElement
    const id = target.getAttribute('href') as string
    this.showTab(id)
    history.pushState({}, '', id)
  }

  private showTab(id: string): void {
    this.panelTargets.forEach((panel) => {
      panel.classList.toggle('hidden', panel.id !== id.substring(1))
    })

    this.tabTargets.forEach((tab) => {
      const isSelected = tab.getAttribute('href') === id
      tab.classList.toggle('border-indigo-500', isSelected)
      tab.classList.toggle('text-indigo-600', isSelected)
      tab.classList.toggle('border-transparent', !isSelected)
      tab.classList.toggle('text-gray-500', !isSelected)
    })
  }
}
