import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toggleButton', 'toggleIcon', 'children', 'toggleAllButton']

  expanded!: boolean
  toggleButtonTarget!: HTMLElement
  toggleIconTarget!: HTMLElement
  childrenTarget!: HTMLElement
  toggleAllButtonTarget?: HTMLElement
  hasToggleAllButtonTarget!: boolean

  connect() {
    this.expanded = false
    if (this.hasToggleAllButtonTarget) {
      this.updateToggleAllButtonText()
    }
  }

  toggle() {
    this.childrenTarget.classList.toggle('hidden')
    this.toggleIconTarget.classList.toggle('-rotate-90')
  }

  toggleAll() {
    if (this.expanded) {
      this.collapseAll()
    } else {
      this.expandAll()
    }
    this.expanded = !this.expanded
    if (this.hasToggleAllButtonTarget) {
      this.updateToggleAllButtonText()
    }
  }

  expandAll() {
    this.expandChildren(this.element as HTMLElement)
  }

  collapseAll() {
    this.collapseChildren(this.element as HTMLElement)
  }

  expandChildren(element: HTMLElement) {
    const children = element.querySelectorAll<HTMLElement>(
      '[data-group-target="children"]'
    )
    const icons = element.querySelectorAll<HTMLElement>(
      '[data-group-target="toggleIcon"]'
    )

    children.forEach((child) => {
      child.classList.remove('hidden')
    })

    icons.forEach((icon) => {
      icon.classList.remove('-rotate-90')
    })

    children.forEach((child) => {
      this.expandChildren(child)
    })
  }

  collapseChildren(element: HTMLElement) {
    const children = element.querySelectorAll<HTMLElement>(
      '[data-group-target="children"]'
    )
    const icons = element.querySelectorAll<HTMLElement>(
      '[data-group-target="toggleIcon"]'
    )

    children.forEach((child) => {
      // Only collapse children of the top-level groups
      if (child.closest('[data-controller="group"]') !== this.element) {
        child.classList.add('hidden')
      }
    })

    icons.forEach((icon) => {
      // Only rotate icons of the children
      if (icon.closest('[data-controller="group"]') !== this.element) {
        icon.classList.add('-rotate-90')
      }
    })

    children.forEach((child) => {
      this.collapseChildren(child)
    })
  }

  updateToggleAllButtonText() {
    if (this.toggleAllButtonTarget) {
      this.toggleAllButtonTarget.textContent = this.expanded
        ? 'Collapse All'
        : 'Expand All'
    }
  }
}
